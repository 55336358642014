import React from 'react';
import Scroll from './Scroll';
import config from '../../config';
import headImg from '../assets/images/logo.svg';

export default function Header() {
  return (
    <section id="header">
      <header className="major">
        <p>
          <img
            src={headImg}
            width="259"
            height="255"
            alt="EventsMadeLive - Video Platform Simplified"
          />
        </p>
        <p>{config.subHeading}</p>
      </header>
      <div className="container">
        <ul className="actions special">
          <li>
            <Scroll type="id" element="one">
              <a href="#one" className="button primary">
                Begin
              </a>
            </Scroll>
          </li>
        </ul>
      </div>
    </section>
  );
}
