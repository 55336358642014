import React from 'react';
import Scrollspy from 'react-scrollspy';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Scroll from '../components/Scroll';
import ContactForm from '../components/ContactForm';
import overLayPic from '../assets/images/overlay.png';

const sections = [
  { id: 'one', bg: require('../assets/images/switcher.jpg') },
  { id: 'two', bg: require('../assets/images/football.jpg') },
  { id: 'three', bg: require('../assets/images/wedding.jpg') },
  { id: 'four', bg: require('../assets/images/sundance.jpg') },
];
const IndexPage = () => (
  <Layout>
    <Header />

    <section id="one" className="main">
      <div className="container">
        <span className="image fit primary">
          <img src={sections[0].bg} alt="video switcher" />
        </span>
        <div className="content">
          <header className="major">
            <h2>Video - On Your Terms</h2>
          </header>
          <p>
            The existing social video platforms are all great. We use them
            ourselves. But there is a big catch - you don't own the platform. As
            a video creator, you upload great content. Google or Amazon then
            decides what to do with that content. They will sell ads on your
            content. If you are lucky, you may even see a small cut from these
            ad sales.
          </p>
          <p>
            You work hard to bring an audience to your videos. But because you
            don't control the AI algorithm, it is a bit of a lottery game. These
            platforms will also happily recommend other videos, encouraging
            users to leave your channel rather than explore your own content
            library.
          </p>
        </div>

        <Scroll type="id" element="two">
          <a href="#two" className="goto-next ">
            Next
          </a>
        </Scroll>
      </div>
    </section>
    <section id="two" className="main">
      <div className="container">
        <span className="image fit primary">
          <img src={sections[1].bg} alt="sporting event" />
        </span>
        <div className="content">
          <header className="major">
            <h2>Your Own Platform</h2>
          </header>
          <p>
            <strong>EventsMadeLive is different.</strong> It's a product
            designed for video creators and video library owners to build out
            their own branded platform. Sell tickets to your live events or
            charge a subscription fee for access to your training videos. Or
            sell your own ads. The bottom line is you have more
            control.
          </p>
          <p>
            EventsMadeLive aims to be an all-in-one solution for monetizing your
            video business. We handle the complicated bit so you can focus on
            growing your business. We host and maintain your video library and
            website on reliable infrastructure and Content Delivery Network to
            ensure your customers can have the best viewing experience.
          </p>
          <p>
            We are passionate about democratizing video broadcasting and
            <strong> enabling you</strong> to charge for access to live content
            in addition to your on demand video library. Whether you are a video
            Pro or just getting started (we'll show you the ropes), owning your
            own branded platform is the best way to cultivate your unique
            audience.
          </p>
          <p>
            <strong>EventsMadeLive handles everything</strong>, so you can
            eliminate many of these distinct costs and vendors:
          </p>
          <ul>
            <li>Expensive streaming server licenses</li>
            <li>Video Bandwidth</li>
            <li>Website developer contracts and hosting providers</li>
            <li>Server maintenance</li>
            <li>Infrastructure management</li>
            <li>HTTPS certificate fees</li>
            <li>Video monetization revenue share models</li>
            <li>OTT fees</li>
            <li>Video transcoding fees</li>
            <li>Streaming origin servers</li>
            <li>Transactional email services</li>
            <li>Cloud Video storage costs</li>
            <li>Content Delivery Networks</li>
            <li>Expensive Payment handling fees</li>
            <li>Large upfront platform development investment requirements</li>
          </ul>
          <Scroll type="id" element="three">
            <a href="#two" className="goto-next ">
              Next
            </a>
          </Scroll>
        </div>
      </div>
    </section>
    <section id="three" className="main">
      <div className="container">
        <span className="image fit primary">
          <img src={sections[3].bg} alt="stage event" />
        </span>
        <div className="content">
          <header className="major">
            <h2>Your Video Platform For</h2>
          </header>
          <p>
            <strong>EventsMadeLive</strong> provides a solution that is ideal
            for:
          </p>
          <ul>
            <li>Local Content Providers</li>
            <li>School Sports Programs</li>
            <li>Special Events</li>
            <li>Online Courses</li>
            <li>Content Enterprises</li>
            <li>Universities</li>
            <li>Video Professionals</li>
            <li>Corporate Training Catalogs</li>
            <li>Broadcasters</li>
            <li>Video Portfolios</li>
          </ul>
          <p>
            <strong>EventsMadeLive</strong> makes it easy to build a
            professional website that centers and showcases your content. So
            whether you are streaming sports or a corporate training program, we
            have a solution for you.
          </p>
        </div>
        <Scroll type="id" element="four">
          <a href="#four" className="goto-next ">
            Next
          </a>
        </Scroll>
      </div>
    </section>

    <section id="four" className="main">
      <div className="container">
        <span className="image fit primary">
          <img src={sections[2].bg} alt="sundance" />
        </span>
        <div className="content">
          <header className="major">
            <h2>The Journey</h2>
          </header>
          <p>
            I am a video creator just like you. I started by founding a
            Broadcast Club at my University and building out the studio. Since
            then, I've done work on special events such as Sundance Film
            Festival. However, I've been looking for a way to make streaming
            economically viable beyond annual special events.
          </p>
          <p>
            A friend who works in the sports streaming field asked me what it
            would take to build out a platform to monetize live video. He was
            frustrated with the outrageous quotes received from existing
            providers and the number of moving parts to get something like this
            started. This is an arena ripe for disruption. After an exhaustive
            search to find a better way to monetize live local sports streaming,
            I found that building was the only way.
          </p>
          <p>
            I wanted a solution that was incredibly easy to use. It shouldn't be
            necessary to contract with dozens of vendors to build out a branded
            website with live streaming monetization capabilities. The pricing
            should be simple, fair, and understandable. EventsMadeLive has been
            under construction, refinement, and testing for the last year. I'm
            excited to report that a public launch date is within reach.
          </p>
        </div>
        <Scroll type="id" element="footer">
          <a href="#footer" className="goto-next ">
            Next
          </a>
        </Scroll>
      </div>
    </section>

    <section id="footer">
      <div className="container">
        <header className="major">
          <h2>Be the first to know when we launch</h2>
        </header>
        <ContactForm />
      </div>
      <Footer />
    </section>

    <Scrollspy
      items={sections.map(s => s.id)}
      currentClassName="active"
      offset={0}
      componentTag={'div'}
    >
      {sections.map(s => {
        return (
          <div
            key={s.id}
            className="main-bg"
            id={`${s.id}-bg`}
            style={{ backgroundImage: `url(${overLayPic}), url(${s.bg})` }}
          />
        );
      })}
    </Scrollspy>
  </Layout>
);

export default IndexPage;
