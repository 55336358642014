import React from 'react';

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export default function ContactForm() {
  const [state, setState] = React.useState({
    name: '',
    email: '',
    message: '',
  });
  const [showModal, setShowModal] = React.useState(false);
  const [modalError, setModalError] = React.useState(false);

  const handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = event => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...state }),
    })
      .then(() => {
        !modalError && setModalError(false);
        setState({
          name: '',
          email: '',
          message: '',
        });
        setShowModal(true);
      })
      .catch(() => {
        setModalError(true);
        setShowModal(true);
      });
    event.preventDefault();
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <form
      name="contact"
      method="POST"
      data-netlify="true"
      action="/thank-you"
      netlify-honeypot="last-name"
      onSubmit={handleSubmit}
      onClick={closeModal}
    >
      <div className={showModal ? 'modal' : 'modal modal-hidden'}>
        {modalError ? (
          <p>
            An error occurred while attempting to submit your message.
            <br />
            Please contact me at{' '}
            <a
              href={`mailto:brandon@eventsmadelive.com?subject=Website%20Contact&body=${encodeURIComponent(
                state.message
              )}`}
            >
              brandon@brandonkalinowski.com
            </a>
          </p>
        ) : (
          <p>
            You've just taken the first step to owning your own video platform.
            Thank you for your interest in EventsMadeLive! Expect an email from
            us as soon as we have more news to share.
          </p>
        )}
        <button type="button" onClick={closeModal}>
          Okay
        </button>
      </div>
      <div className="row gtr-uniform">
        <input type="hidden" name="form-name" value="contact" />
        <div className="col-6 col-12-xsmall">
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Name"
            onChange={handleInputChange}
            value={state.name}
          />
        </div>
        <div className="col-6 col-12-xsmall hidden">
          <input
            type="text"
            name="last-name"
            id="last-name"
            onChange={handleInputChange}
            placeholder="Last Name"
          />
        </div>
        <div className="col-6 col-12-xsmall">
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            onChange={handleInputChange}
            value={state.email}
          />
        </div>
        <div className="col-12">
          <textarea
            name="message"
            id="message"
            placeholder="Message (optional)"
            onChange={handleInputChange}
            value={state.message}
            rows="4"
          />
        </div>
        <div className="col-12">
          <ul className="actions special">
            <li>
              <input type="submit" value="Send Message" className="primary" />
            </li>
          </ul>
        </div>
      </div>
    </form>
  );
}
